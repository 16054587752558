import axios from "axios"
import { ApiUrl } from "../utils/network"

export async function getRecentSearch() {
    const url = `${ApiUrl}/api/v1/customers/search-histories`

    return axios.get(url, {
        params: {
            orderBy: "",
            order: -1,
            page: 1,
            limit: 10
        }
    })
}

export async function deleteRecentSearch(id: string) {
    const url = `${ApiUrl}/api/v1/customers/search-histories/${id}`

    return axios.delete(url)
}
