import React, { forwardRef, ForwardRefRenderFunction, ReactNode, useImperativeHandle, useState } from "react"
import { NarrowDownSvg } from "../../assets/svg"
import { twMerge } from "tailwind-merge"
import SVG from "react-inlinesvg"
import clsx from "clsx"

interface DropDownProps {
    items: Array<any>
    disabled?: boolean
    classNames?: string
    buttonClassNames?: string
    containerListClassNames?: string
    listClassNames?: string
    itemClassNames?: string

    itemIconClassNames?: string
    narrowClassNames?: string
    iconClassName?:string,
    labelClassNames?: string
    defaultSelected?: any
    icon?: ReactNode
    afterSelected?: (item: any) => void
    isShowTopNarrow?: boolean
    topNarrowClassNames?: string
    itemTemplate?: (item: any) => any
}

interface DropDownRef {
    reset: () => void

    close: () => void
}

const DropdownRender: ForwardRefRenderFunction<DropDownRef, DropDownProps> = (
    {
        classNames,
        buttonClassNames,
        containerListClassNames,
        listClassNames,
        itemClassNames,
        itemIconClassNames,
        narrowClassNames,
        iconClassName,
        labelClassNames,
        items,
        disabled,
        defaultSelected,
        icon,
        afterSelected,
        isShowTopNarrow,
        topNarrowClassNames,
        itemTemplate
    }: DropDownProps,
    ref
) => {
    const [isOpen, setIsOpen] = useState(false)

    const open = () => {
        setIsOpen((prevState) => !prevState)
    }

    const close = () => {
        setIsOpen(false)
    }

    const [selected, setSelected] = useState(defaultSelected ?? items[0])
    const onSelected = (item) => {
        setSelected(item)
        setIsOpen(false)

        if (typeof afterSelected === "function") {
            afterSelected(item)
        }
    }

    useImperativeHandle(ref, () => ({
        reset: () => {
            setSelected(defaultSelected ?? items[0])
        },

        setSelected: (item) => {
            setSelected(item)
            setIsOpen(false)
        },

        close: () => {
            setIsOpen(false)
        }
    }))

    const defaultTemplate = (item) => (
        <>
            <div
                key={item.id}
                className={twMerge(
                    "ml-[17px] flex items-center py-[5px] text-xs text-neutral-1 group hover:text-primary",
                    itemClassNames
                )}
                onClick={() => onSelected(item)}
            >
                {item.icon && (
                    <SVG
                        src={item.icon}
                        className={twMerge(clsx(
                            `mr-[11px] h-[19px] w-[19px] text-neutral-1-100 group-hover:text-accent-1 active:text-accent-1`,
                            item.id === selected?.id && "active",
                            itemIconClassNames
                        ))}
                    />
                )}
                {item.label || item.text}
            </div>
        </>
    )

    return (
        <div className={twMerge("relative", classNames)} onMouseLeave={close}>
            <div>
                <button
                    type="button"
                    onClick={open}
                    className={twMerge(
                        "group relative flex h-[38px] w-full items-center rounded-[5px] bg-white text-xs text-neutral-1 outline-0 hover:bg-primary hover:text-white disabled:bg-neutral-1-200 disabled:text-neutral-1-300",
                        buttonClassNames
                    )}
                    disabled={disabled}
                >
                    {selected?.icon ? (
                        <SVG
                            src={selected.icon}
                            className={twMerge("h-[22px] w-[22px] text-primary group-hover:text-primary", iconClassName)}
                        />
                    ) : (
                        icon
                    )}
                    <div className={twMerge("ml-[6px] flex", labelClassNames)}>{selected?.label || selected?.text}</div>
                    {items.length > 0 && (
                        <div
                            className={`absolute right-[17px] flex group-disabled:opacity-30 ${
                                isOpen ? "rotate-180" : ""
                            }`}
                        >
                            <NarrowDownSvg
                                width={5}
                                height={7}
                                className={twMerge("group-hover:fill-white", narrowClassNames)}
                            />
                        </div>
                    )}
                </button>
            </div>
            {isOpen && items.length > 0 && (
                <div className={twMerge(clsx("absolute left-0 z-30 w-full", containerListClassNames))}>
                    {isShowTopNarrow && (
                        <div
                            className={twMerge(
                                clsx(
                                    "b-inset-center top-right left-[20px] top-2 h-[12px] w-[12px] rotate-45 bg-neutral-1-100",
                                    topNarrowClassNames
                                )
                            )}
                        ></div>
                    )}
                    <div
                        className={twMerge(
                            "mt-[5px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ",
                            listClassNames
                        )}
                    >
                        <div className="py-2" role="none">
                            {items.map((item) => {
                                return (
                                    <div key={item.id}>{itemTemplate ? itemTemplate(item) : defaultTemplate(item)}</div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const Dropdown = forwardRef(DropdownRender)

export { Dropdown }
