import {
    ClaimReferralSvg,
    DiscordSvg,
    GameSvg,
    HomeSvg,
    LogoSvg,
    MarketSvg,
    MoreSvg,
    NoteSvg,
    NotificationSvg,
    Person1Svg,
    QuoraSvg,
    SearchSvg,
    SettingSvg,
    TelegramSvg,
    TwitterSvg,
    WebSvg
} from "../../assets/svg"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "next-i18next"
import { SearchBox } from "./SearchBox"
import { twMerge } from "tailwind-merge"
import clsx from "clsx"
import { Dropdown } from "../forms/dropdown"
import RetainQueryLink from "./RetainQueryLink"

export const MobileMenu = (props) => {
    const { router } = props
    const { query, pathname, asPath } = router

    const { t: tCommon } = useTranslation("common")
    const [isShowSearch, setIsShowSearch] = useState(false)
    const [isShowMore, setIsShowMore] = useState(false)

    useEffect(() => {
        document.body.style.overflow = isShowSearch || isShowMore ? "hidden" : "auto"
    }, [isShowSearch, isShowMore])

    const isHome = pathname === "/"
    const isMyGames = pathname.indexOf("my-games") >= 0
    const isMyAccount = pathname.indexOf("my-account") >= 0
    const isMarkets = pathname.indexOf("search") >= 0
    const isNotifications = pathname.indexOf("notifications") >= 0
    const isPreferences = pathname.indexOf("preferences") >= 0
    const isClaim = pathname.indexOf("claim") >= 0

    const onShowSearch = () => {
        setIsShowSearch((prev) => !prev)
        setIsShowMore(false)
    }

    const onShowMore = () => {
        setIsShowMore((prev) => !prev)
        setIsShowSearch(false)
    }

    const [currentLanguage, setCurrentLanguage] = useState(
        router.locale === "en"
            ? { id: "en", label: tCommon("setting.english") }
            : { id: "zh", label: tCommon("setting.chinese") }
    )

    const languageRef = useRef(null)

    const onSelectLanguage = (language) => {
        router.push({ pathname, query }, asPath, { locale: language.id })

        setIsShowMore(false)
    }

    useEffect(() => {
        const language =
            router.locale === "en"
                ? { id: "en", label: tCommon("setting.english") }
                : { id: "zh", label: tCommon("setting.chinese") }

        if (languageRef && languageRef.current) {
            languageRef.current.setSelected(language)

            setCurrentLanguage(language)
        }

        setIsShowSearch(false)
    }, [router, tCommon])

    return (
        <>
            <div
                className={twMerge(
                    clsx(
                        "sticky bottom-0 z-40 h-0 w-full overflow-hidden bg-white px-[40px] transition-all duration-500",
                        isShowSearch && "h-[100vh]"
                    )
                )}
            >
                <SearchBox router={router} isShowRecentSearch={true} className={"ml-0 mt-[40px] block w-full"} />
            </div>
            <div
                className={twMerge(
                    clsx(
                        "sticky bottom-0 z-40 h-0 w-full overflow-hidden bg-white transition-all duration-500",
                        isShowMore && "h-[100vh]"
                    )
                )}
            >
                <div className={"pt-[34px]"}>
                    <div className={"px-7"}>
                        <LogoSvg width={59} height={30} className={"fill-[#546F7A]"} />
                    </div>
                    <div className={"mt-[45px] px-[18px] text-22 font-semibold leading-7"}>
                        <div className={"border-b border-[#E6E6E6] px-[14px] pb-[15px]"}>
                            <RetainQueryLink href={"/claim"}>
                                <a className={clsx("flex items-center", isClaim && "text-accent-1")}>
                                    <div className={"b-flex-center mr-[18px] h-[20px] w-[20px]"}>
                                        <ClaimReferralSvg
                                            width={26}
                                            height={26}
                                            className="fill-[#546F7A] group-hover:fill-accent-1 group-hover:text-accent-1"
                                        />
                                    </div>
                                    {tCommon("button.claim")}
                                </a>
                            </RetainQueryLink>
                        </div>
                        <div className={"border-b border-[#E6E6E6] py-[15px] px-[14px]"}>
                            <RetainQueryLink href={"/my-games"}>
                                <a className={clsx("flex items-center", isMyGames && "text-accent-1")}>
                                    <div className={"b-flex-center mr-[18px] h-[20px] w-[20px]"}>
                                        <GameSvg
                                            width={23}
                                            height={15}
                                            className="fill-[#546F7A] group-hover:fill-accent-1 group-hover:text-accent-1"
                                        />
                                    </div>
                                    {tCommon("menu.myGames")}
                                </a>
                            </RetainQueryLink>
                        </div>
                        <div className={"border-b border-[#E6E6E6] py-[15px] px-[14px]"}>
                            <RetainQueryLink href={"/my-account"}>
                                <a className={clsx("flex items-center", isMyAccount && "text-accent-1")}>
                                    <div className={"b-flex-center mr-[18px] h-[20px] w-[20px]"}>
                                        <Person1Svg
                                            width={20}
                                            height={22}
                                            className={twMerge(clsx("fill-[#546F7A]", isMyAccount && "fill-accent-1"))}
                                        />
                                    </div>
                                    {tCommon("menu.personalInfo")}
                                </a>
                            </RetainQueryLink>
                        </div>
                        <div className={"border-b border-[#E6E6E6] py-[15px] px-[14px]"}>
                            <RetainQueryLink href={"/notifications"}>
                                <a className={clsx("flex items-center", isNotifications && "text-accent-1")}>
                                    <div className={"b-flex-center mr-[18px] h-[20px] w-[20px]"}>
                                        <NotificationSvg
                                            width={19}
                                            height={22}
                                            className={twMerge(
                                                clsx("fill-[#546F7A]", isNotifications && "fill-accent-1")
                                            )}
                                        />
                                    </div>
                                    {tCommon("menu.notifications")}
                                </a>
                            </RetainQueryLink>
                        </div>
                        <div className={"border-b border-[#E6E6E6] py-[15px] px-[14px]"}>
                            <RetainQueryLink href={"/preferences"}>
                                <a className={clsx("flex items-center", isPreferences && "text-accent-1")}>
                                    <div className={"b-flex-center mr-[18px] h-[20px] w-[20px]"}>
                                        <SettingSvg
                                            width={20}
                                            height={20}
                                            className={twMerge(
                                                clsx("fill-[#546F7A]", isPreferences && "fill-accent-1")
                                            )}
                                        />
                                    </div>
                                    {tCommon("menu.preferences")}
                                </a>
                            </RetainQueryLink>
                        </div>
                        <div className={"border-b border-[#E6E6E6] py-[15px] px-[14px]"}>
                            <a
                                href={"https://docs.bbb.wtf"}
                                target={"_blank"}
                                rel={"noreferrer"}
                                className={clsx("flex items-center")}
                            >
                                <div className={"b-flex-center mr-[18px] h-[20px] w-[20px]"}>
                                    <NoteSvg
                                        width={17}
                                        height={22}
                                        className={twMerge(clsx("fill-[#546F7A]", isPreferences && "fill-accent-1"))}
                                    />
                                </div>
                                {tCommon("menu.getStarted")}
                            </a>
                        </div>
                        <div
                            className={
                                "flex items-center justify-between border-b border-[#E6E6E6] py-[15px] px-[14px]"
                            }
                        >
                            <div className={clsx("flex items-center")}>
                                <div className={"b-flex-center mr-[18px] h-[20px] w-[20px]"}>
                                    <WebSvg width={20} height={20} className={twMerge(clsx("fill-[#546F7A]"))} />
                                </div>
                                {tCommon("setting.language")}
                            </div>
                            <div className={"w-[80px]"}>
                                {currentLanguage && (
                                    <Dropdown
                                        ref={languageRef}
                                        items={[
                                            {
                                                id: "en",
                                                label: tCommon("setting.english")
                                            },
                                            {
                                                id: "zh",
                                                label: tCommon("setting.chinese")
                                            }
                                        ]}
                                        defaultSelected={currentLanguage}
                                        buttonClassNames="h-[36px] ml-4 bg-transparent hover:bg-transparent text-18 hover:text-black"
                                        listClassNames={"text-16"}
                                        itemClassNames={"text-16"}
                                        narrowClassNames={"w-[10px] group-hover:fill-black h-[10px] b-insert-center"}
                                        afterSelected={onSelectLanguage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mt-9 flex gap-6 px-[32px]">
                        <figure>
                            <a href={"https://twitter.com/BBB_wtfpusu"} target={"_blank"} rel={"noreferrer"}>
                                <TwitterSvg width={24} height={21} className="fill-[#546F7A]" />
                            </a>
                        </figure>
                        <figure>
                            <a href={"https://t.me/BBB_globalchat"} target={"_blank"} rel={"noreferrer"}>
                                <TelegramSvg width={19} height={21} className="fill-[#546F7A]" />
                            </a>
                        </figure>
                        <figure>
                            <a href={"https://discord.com/invite/wmPK33xWu5"} target={"_blank"} rel={"noreferrer"}>
                                <DiscordSvg width={19} height={21} className="fill-[#546F7A]" />
                            </a>
                        </figure>
                        <figure>
                            <a href={"https://putuporshutupbybbb.quora.com/"} target={"_blank"} rel={"noreferrer"}>
                                <QuoraSvg width={21} height={21} className="fill-[#546F7A]" />
                            </a>
                        </figure>
                    </div>
                </div>
            </div>

            <div
                className={
                    "b-flex-center sticky bottom-0 z-50 h-[75px] justify-evenly border border-[#DFDDDE] bg-white text-10 leading-[12px] lg:hidden"
                }
            >
                <div className={"text-center"}>
                    <RetainQueryLink href={"/"}>
                        <a className={clsx(isHome && "text-accent-1")}>
                            <div className={"b-flex-center h-[26px]"}>
                                <HomeSvg
                                    width={19}
                                    height={20}
                                    className={twMerge(clsx("fill-[#546F7A]", isHome && "fill-accent-1"))}
                                />
                            </div>
                            {tCommon("menu.home")}
                        </a>
                    </RetainQueryLink>
                </div>
                <div className={"text-center"}>
                    <RetainQueryLink href={"/search"}>
                        <a className={clsx(isMarkets && "text-accent-1")}>
                            <div className={"b-flex-center h-[26px]"}>
                                <MarketSvg
                                    width={22}
                                    height={20}
                                    className={twMerge(clsx("fill-[#546F7A]", isMarkets && "fill-accent-1"))}
                                />
                            </div>
                            {tCommon("menu.market")}
                        </a>
                    </RetainQueryLink>
                </div>
                <RetainQueryLink href={"/create-game"}>
                    <a>
                        <div
                            className={
                                "search-border b-flex-center mobile-create-game h-[50px] w-[50px] text-30 font-bold"
                            }
                        >
                            +
                        </div>
                    </a>
                </RetainQueryLink>
                <div className={"cursor-pointer text-center"} onClick={onShowSearch}>
                    <div className={"b-flex-center h-[26px]"}>
                        <SearchSvg
                            width={16}
                            height={20}
                            className={twMerge(clsx("fill-[#546F7A]", isShowSearch && "fill-accent-1"))}
                        />
                    </div>
                    {tCommon("menu.search")}
                </div>
                <div className={"text-center"} onClick={onShowMore}>
                    <div className={"b-flex-center h-[26px]"}>
                        <MoreSvg
                            width={19}
                            height={14}
                            className={twMerge(clsx("fill-[#546F7A]", isShowMore && "fill-accent-1"))}
                        />
                    </div>
                    {tCommon("menu.more")}
                </div>
            </div>
        </>
    )
}
