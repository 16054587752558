import Link, { LinkProps } from "next/link"
import { useRouter } from "next/router"
import { PropsWithChildren } from "react"

const RetainQueryLink = ({ href, ...props }: LinkProps & PropsWithChildren<any>) => {
    const router = useRouter()
    const pathname = typeof href === "object" ? href.pathname : href
    const query = typeof href === "object" && typeof href.query === "object" ? href.query : {}

    const allowedParams = ["utm_source", "utm_medium", "utm_campaign", "utm_id", "utm_term", "utm_content"]

    const routerQuery = Object.assign({}, router.query)
    for (let key in routerQuery) {
        if (allowedParams.indexOf(key.toLowerCase()) === -1) {
            delete routerQuery[key]
        }
    }

    return (
        <Link
            {...props}
            href={{
                pathname: pathname,
                query: {
                    ...routerQuery,
                    ...query
                }
            }}
        />
    )
}
export default RetainQueryLink
