import { Footer } from "./Footer"
import { Header } from "./Header"
import useGlobalStore from "../../hooks/useGlobalStore"
import { LoginModal } from "./LoginModal"
import { ErrorModal } from "./ErrorModal"
import { useRouter } from "next/router"
import dayjs from "dayjs"
import { MobileMenu } from "./MobileMenu"

interface LayoutProps {
    children?: React.ReactNode
}

export const Layout = (props: LayoutProps) => {
    const router = useRouter()
    dayjs.locale(router.locale)

    const { isOpenLoginModal, setIsOpenLoginModal, popupMessage, setPopupMessage } = useGlobalStore()

    function closeModal() {
        setIsOpenLoginModal(false)
    }

    return (
        <div>
            {isOpenLoginModal && <LoginModal isOpen={isOpenLoginModal} onRequestClose={closeModal} />}
            {popupMessage && (!popupMessage.type || popupMessage.type === "ERROR") && (
                <ErrorModal
                    message={popupMessage.message}
                    buttonText={popupMessage.buttonText}
                    onClick={popupMessage.onClick}
                    isOpen={!!popupMessage}
                    onRequestClose={() => setPopupMessage(null)}
                />
            )}
            <div>
                <Header />
                {props.children}
            </div>
            <Footer />
            <MobileMenu router={router} />
        </div>
    )
}
