import StyledModal, { ModalProps } from "./StyledModal"
import close from "../assets/imgs/home/bg_x.png"
import React, { useState } from "react"
import useGlobalStore from "../hooks/useGlobalStore"
import { useTranslation } from "next-i18next"
import { isEmail, tryGetErrorMessage } from "../utils/string"
import OtpInput from "react18-input-otp"
import { loginByEmail, verifyLoginEmail } from "../services/account"
import { useRouter } from "next/router"
import { twMerge } from "tailwind-merge"
import clsx from "clsx"
import { Loading } from "./shared/Loading"
import { ConnectButton } from "@rainbow-me/rainbowkit"
import { useAccount } from "wagmi"
import { Wallet1Svg } from "../assets/svg"

interface ConnectModalProps extends ModalProps {
    onConnected?: () => void
}

const ConnectModal = ({ isOpen, onRequestClose, onConnected }: ConnectModalProps) => {
    const { t: tCommon } = useTranslation("common")
    const { isLoggedIn, isValidAccount, setUser, user } = useGlobalStore()

    const router = useRouter()
    const { query } = router

    const { address: account, isConnecting, isDisconnected, isConnected } = useAccount()

    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [isShowVerificationCode, setIsShowVerificationCode] = useState(false)
    const [otpValue, setOtpValue] = useState("")
    const [error, setError] = useState("")

    const onKeyPress = async (event) => {
        if (event.key === "Enter") {
            await onLoginEmail()
        }
    }

    const onLoginEmail = async () => {
        setError("")

        if (!email || !isEmail(email.trim())) {
            return
        }
        try {
            setIsLoading(true)

            await loginByEmail(email)

            setIsShowVerificationCode(true)
        } catch (ex) {
            setError(tryGetErrorMessage(ex))
        } finally {
            setIsLoading(false)
        }
    }

    const onVerification = async () => {
        setError("")

        try {
            setIsLoading(true)

            const response = await verifyLoginEmail(email, otpValue, query)

            if (response.status === 200) {
                const loginInfo = response.data.data

                setUser({
                    id: loginInfo.id,
                    token: loginInfo.token,
                    refreshToken: loginInfo.refreshToken,
                    chatToken: loginInfo.chatCustomerData?.accessToken,
                    tokenExpire: loginInfo.tokenExpire,
                    profileImage: loginInfo.profileImage,
                    email: loginInfo.email,
                    walletAddress: loginInfo.walletAddress
                })

                setIsShowVerificationCode(false)
                setEmail("")

                onRequestClose()
            }
        } catch (ex) {
            console.log(ex)

            setError(tryGetErrorMessage(ex))
        } finally {
            setIsLoading(false)
        }
    }

    const onModalClose = () => {
        setIsShowVerificationCode(false)
        setEmail("")

        onRequestClose()
    }

    return (
        <StyledModal isOpen={isOpen} onRequestClose={onModalClose}>
            {isLoading && <Loading />}
            <div
                className={twMerge(
                    clsx(
                        "relative m-auto h-[429px] w-[90%] rounded-[11px] border border-[#808080] bg-[#F8F8F8] bg-[url(../assets/imgs/bg/connect-bg.svg)] bg-contain bg-top bg-no-repeat px-[20px] pb-[41px] text-center lg:w-[533px] lg:px-[54px]",
                        isLoggedIn && !isValidAccount && "h-[250px]"
                    )
                )}
            >
                <div className="absolute right-[13px] top-[13px] text-right">
                    <img src={close.src} onClick={onModalClose} className="w-[15px] hover:opacity-50" />
                </div>
                {!isShowVerificationCode && (
                    <>
                        <div className={"mt-[129px] text-28 font-bold leading-[44px] text-neutral-1-500"}>
                            {tCommon("loginToBBB")}
                        </div>
                        {!isLoggedIn && (
                            <>
                                <div className={"mt-[21px]"}>
                                    <input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyPress={onKeyPress}
                                        className={
                                            "b-input h-[46px] w-full rounded-[5px] border border-[#ADADAD] font-primary text-14"
                                        }
                                        placeholder={tCommon("email")}
                                    />
                                    <div className={"mt-[17px]"}>
                                        <button
                                            onClick={onLoginEmail}
                                            className={
                                                "b-flex-center h-[46px] w-full rounded-[5px] bg-[#FFC90A] font-medium hover:text-white"
                                            }
                                        >
                                            {tCommon("button.continue")}
                                        </button>
                                    </div>
                                </div>
                                <div className={"b-flex-center mt-[17px] h-[17px]"}>
                                    <div className={"h-[1px] w-[189px] bg-[#ADADAD]"}></div>
                                    <div className={"b-flex-center px-4 text-14 leading-[0px] text-[#ADADAD]"}>
                                        {tCommon("or")}
                                    </div>
                                    <div className={"h-[1px] w-[189px] bg-[#ADADAD]"}></div>
                                </div>
                            </>
                        )}

                        <ConnectButton.Custom>
                            {({
                                account,
                                chain,
                                openAccountModal,
                                openChainModal,
                                openConnectModal,
                                authenticationStatus,
                                mounted
                            }) => {
                                return (
                                    <div
                                        className={
                                            "b-flex-center text-16 mt-[17px] h-[46px] w-full rounded-[7px] bg-[#EAECEF] font-medium text-neutral-1-500 hover:border-accent-1 hover:text-accent-1 cursor-pointer"
                                        }
                                        onClick={openConnectModal}
                                    >
                                        <Wallet1Svg width={30} height={30} className={"mr-[15px]"} />
                                        {!account ? tCommon("menu.connect") : tCommon("menu.connect")}
                                    </div>
                                )
                            }}
                        </ConnectButton.Custom>
                    </>
                )}
                {isShowVerificationCode && (
                    <>
                        <div className={"mt-[33px] text-28 font-bold leading-[44px] text-neutral-1-500"}>
                            {tCommon("checkEmail")}
                        </div>
                        <div className={"mt-[26px] text-[#212838]"}>{tCommon("sendingEmail")}</div>
                        <div className={"mt-[10px] text-center font-medium text-primary"}>{email}</div>
                        <div>
                            <OtpInput
                                value={otpValue}
                                onChange={(otp) => setOtpValue(otp)}
                                numInputs={6}
                                containerStyle={"mt-[28px] w-full flex justify-center items-center"}
                                inputStyle={{
                                    width: 50,
                                    height: 50,
                                    margin: "0 5px",
                                    borderRadius: 5,
                                    backgroundColor: "#FFF",
                                    border: "1px solid #ADADAD"
                                }}
                                focusStyle={{ outline: "none" }}
                            />
                        </div>
                        <div className={"mt-[30px]"}>
                            <button
                                onClick={onVerification}
                                className={"b-flex-center h-[46px] w-full rounded-[5px] bg-[#FFC90A] hover:text-white"}
                            >
                                {tCommon("button.continue")}
                            </button>
                        </div>
                        <div className={"mt-2 h-[30px] text-12 text-red-500"}>{error}</div>
                        <div>
                            {tCommon("didNotGetEmail")}
                            <button
                                onClick={onLoginEmail}
                                className={"ml-[10px] border-0 bg-none font-medium text-[#0F84F0] hover:text-primary"}
                            >
                                {tCommon("button.resend")}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </StyledModal>
    )
}

export default ConnectModal
