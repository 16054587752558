import React from "react"
import { ButtonBgSvg } from "../../assets/svg"
import StyledModal, { ModalProps } from "../StyledModal"
import Image from "next/future/image"
import failed from "../../assets/imgs/game-details/failed.png"
import close from "../../assets/imgs/home/bg_x.png"

interface ErrorModalProps extends ModalProps {
    message: string
    buttonText?: string
    onClick?: () => void
}

export const ErrorModal = ({ isOpen, onRequestClose, message, buttonText, onClick }: ErrorModalProps) => {
    const handleClick = () => {
        if (onClick) {
            onClick()
        }

        onRequestClose()
    }

    return (
        <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
            <div
                className={
                    "relative mx-auto w-[432px] max-w-[90%] rounded-md bg-[url(../assets/imgs/bg/bbb-white-big.png)] py-6"
                }
            >
                <div className="absolute right-2 top-0 ml-auto pt-[9px]">
                    <img src={close.src} onClick={onRequestClose} className="w-[15px] hover:opacity-50 lg:w-[20px]" />
                </div>
                <div className={"b-flex-center pt-2"}>
                    <Image src={failed} alt={"Failed"} />
                </div>
                <div className={"mt-4 px-4 text-center text-2xl font-bold leading-9"}>
                    <div className={"font-secondary text-accent-3"}>Oh, no...!</div>
                    <div className={"font-secondary text-base font-bold text-neutral-1"}>{message}</div>
                </div>
                <div className={"mt-[26px]"}>
                    <button
                        onClick={handleClick}
                        className="b-button-light relative mx-auto mt-5 block h-[42px] w-[138px] bg-transparent text-center font-secondary text-base font-bold leading-5 hover:text-neutral-1-100"
                        type={"button"}
                    >
                        <ButtonBgSvg className="absolute inset-0 h-full w-full fill-accent-1" />
                        <span className="relative -mt-2 block text-neutral-1-750 hover:text-neutral-1-100">
                            {buttonText || "Close"}
                        </span>
                    </button>
                </div>
            </div>
        </StyledModal>
    )
}
