import Modal from "react-modal"
import { FC } from "react"
import { twMerge } from "tailwind-merge"

export interface ModalProps {
    isOpen: boolean
    onRequestClose: () => void
    shouldCloseOnOverlayClick?: boolean
    overlayClassNames?: string
}

const StyledModal: FC<ModalProps> = ({
    isOpen,
    onRequestClose,
    overlayClassNames,
    shouldCloseOnOverlayClick = true,
    children
}) => {
    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                className="b-modal"
                overlayClassName={twMerge("b-modal-overlay", overlayClassNames)}
                preventScroll={true}
            >
                {children}
            </Modal>
        </>
    )
}

export default StyledModal
