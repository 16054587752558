import { useEffect, useRef } from "react"

const useOutsideClick = (callback, isNodeAlive: boolean = false) => {
    const ref = useRef<HTMLDivElement>()

    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                if (isNodeAlive && !event.target.isConnected) {
                    return
                }

                callback()
            }
        }

        document.addEventListener("click", handleClick)

        return () => {
            document.removeEventListener("click", handleClick)
        }
    }, [ref])

    return ref
}

export default useOutsideClick
