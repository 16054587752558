import { ReactNode, useEffect, useState } from "react"
import useMetaMaskOnboarding from "../hooks/useMetaMaskOnboarding"
import { shortenHex, truncateLength, tryGetErrorMessage } from "../utils/string"
import { login } from "../services/account"
import useGlobalStore from "../hooks/useGlobalStore"
import { twMerge } from "tailwind-merge"
import { useTranslation } from "next-i18next"
import { AllSupportedChainIds, AllSupportedMainnetChainIds, SupportedChainIds } from "../utils/network"
import ConnectModal from "./ConnectModal"
import { useRouter } from "next/router"
import { mutate } from "swr"
import { useAccount, useDisconnect, useEnsName, useWalletClient } from "wagmi"

type AccountProps = {
    triedToEagerConnect: boolean
    buttonClassNames?: string
    background?: ReactNode
}

const Account = ({ triedToEagerConnect, buttonClassNames = "", background }: AccountProps) => {
    const router = useRouter()
    const { query } = router
    const { t: tCommon } = useTranslation("common")

    const { user, setUser, isLoggedIn, isValidAccount, setIsOpenLoginModal, setPopupMessage, isOpenLoginModal } =
        useGlobalStore()
    const { address: account, isConnecting, isDisconnected, isConnected } = useAccount()
    const { data: walletClient, isError, error } = useWalletClient()

    const { disconnect } = useDisconnect()

    const { isWeb3Available } = useMetaMaskOnboarding()

    const clearCache = () => mutate(() => true, undefined, { revalidate: true })

    useEffect(() => {
        if (typeof window !== "undefined" && window.ethereum) {
            // @ts-ignore
            window.ethereum.on("chainChanged", (chain: any) => {
                console.log("chainChanged", chain)

                const chainId = parseInt(chain)
                window.localStorage.chainId = chainId

                if (SupportedChainIds.indexOf(chainId) === -1 && AllSupportedChainIds.indexOf(chainId) >= 0) {
                    window.location.href =
                        AllSupportedMainnetChainIds.indexOf(chainId) >= 0
                            ? process.env.NEXT_PUBLIC_BASE_URL
                            : process.env.NEXT_PUBLIC_TEST_BASE_URL
                }
            })

            // @ts-ignore
            window.ethereum.on("accountsChanged", (accounts: Array<string>) => {
                console.log("old account", account)
                console.log("accountsChanged", accounts)

                // if connect to another account before, then we reset all
                if (account && user && user.walletAddress !== accounts[0]) {
                    setUser({})
                    clearCache()
                }
            })
        }
    }, [])

    const onLogin = async () => {
        await clearCache()

        if (user.id || !walletClient || !account) {
            if (isOpenLoginModal) {
                setIsOpenLoginModal(false)
            }

            return
        }

        try {
            const response = await login(account, walletClient, query)
            if (response.status === 200) {
                const loginInfo = response.data.data

                setUser({
                    id: loginInfo.id,
                    token: loginInfo.token,
                    refreshToken: loginInfo.refreshToken,
                    chatToken: loginInfo.chatCustomerData?.accessToken,
                    tokenExpire: loginInfo.tokenExpire,
                    profileImage: loginInfo.profileImage,
                    email: loginInfo.email,
                    walletAddress: loginInfo.walletAddress
                })

                setIsOpenLoginModal(false)
                setIsOpenConnectModal(false)
            }
        } catch (ex: any) {
            if (isConnected) {
                disconnect()
            }

            if (ex.code !== 4001 && ex.code !== -32603) {
                setPopupMessage({
                    message: tryGetErrorMessage(ex)
                })
            }

            return false
        } finally {
        }
    }

    useEffect(() => {
        if (!isLoggedIn && isConnected && walletClient) {
            onLogin()
        }
    }, [walletClient, isConnected, account])

    const { data: ENSName } = useEnsName({ address: account })

    const [isOpenConnectModal, setIsOpenConnectModal] = useState(false)

    if (error || !triedToEagerConnect) {
        return (
            <>
                <ConnectModal
                    isOpen={isOpenConnectModal}
                    onRequestClose={() => setIsOpenConnectModal(false)}
                    onConnected={() => {}}
                />
                <button
                    className={twMerge(
                        "text-12 font-normal text-neutral-1-100 hover:text-accent-1 lg:text-14",
                        buttonClassNames
                    )}
                    onClick={() => setIsOpenConnectModal(true)}
                >
                    {background}
                    <span className="relative">{tCommon("menu.connect")}</span>
                </button>
            </>
        )
    }

    if (!isValidAccount && isLoggedIn) {
        return <div className={"text-12"}>{truncateLength(user.email, 10)}</div>
    }

    if (typeof account !== "string" || !user.id) {
        return (
            <div className={"flex"}>
                <ConnectModal
                    isOpen={isOpenConnectModal}
                    onRequestClose={() => setIsOpenConnectModal(false)}
                    onConnected={() => {}}
                />
                {
                    <button
                        className={twMerge("text-12 font-semibold hover:text-accent-1 lg:text-14", buttonClassNames)}
                        onClick={() => {
                            setIsOpenConnectModal(true)
                            setIsOpenLoginModal(false)
                        }}
                    >
                        {background}
                        <span className="relative">{tCommon("menu.login")}</span>
                    </button>
                }
            </div>
        )
    }

    return <div className="text-12 font-semibold">{ENSName || `${shortenHex(account, 4)}`}</div>
}

export default Account
