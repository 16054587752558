import { signData } from "../utils/security"
import axios from "axios"
import { ApiUrl } from "../utils/network"

export async function login(wallet: string, provider: any, query: any) {
    const url = `${ApiUrl}/api/v1/auth/customers/login`

    let payload = {
        walletAddress: wallet,
        deadline: new Date().getTime() + 3600 * 1000,
        signature: "",
        utmSource: query.utm_source,
        utmMedium: query.utm_medium,
        utmCampaign: query.utm_campaign,
        utmId: query.utm_id,
        utmTerm: query.utm_term,
        utmContent: query.utm_content
    }

    const params = `address: ${payload.walletAddress.toLowerCase()}\ndeadline: ${payload.deadline}`
    payload.signature = await signData(params, wallet, provider)

    return axios.post(url, payload)
}

export async function getProfile() {
    const url = `${ApiUrl}/api/v1/auth/customers/profiles`

    return axios.get(url)
}

export async function getEmailVerification(email) {
    const url = `${ApiUrl}/api/v1/auth/customers/verifications/emails/send-otps`

    return axios.get(url, {
        params: {
            email: email
        }
    })
}

export async function verifyEmailOtp(otp) {
    const url = `${ApiUrl}/api/v1/auth/customers/verifications/emails`

    return axios.put(url, { otp: otp })
}

export async function updateProfile(name, image) {
    const url = `${ApiUrl}/api/v1/auth/customers/profiles`

    const formData = new FormData()
    formData.append("name", name)
    if (image) {
        formData.append("profileImage", image)
    }

    return axios.put(url, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    })
}

export async function getDiscordUrl(redirectUri) {
    const url = `${ApiUrl}/api/v1/auth/customers/verifications/discords/auth-links`

    return axios.get(url, {
        params: {
            redirectUri
        }
    })
}

export async function verifyDiscord(state, code, redirectUri) {
    const url = `${ApiUrl}/api/v1/auth/customers/verifications/discords`

    return axios.put(url, {
        state,
        code,
        redirectUri
    })
}

export async function getTwitterUrl(redirectUri) {
    const url = `${ApiUrl}/api/v1/auth/customers/verifications/twitters/auth-links`

    return axios.get(url, {
        params: {
            redirectUri
        }
    })
}

export async function verifyTwitter(state, code, redirectUri) {
    const url = `${ApiUrl}/api/v1/auth/customers/verifications/twitters`

    return axios.put(url, {
        state,
        code,
        redirectUri
    })
}

export async function verifyTelegram(authResponded: any) {
    const url = `${ApiUrl}/api/v1/auth/customers/verifications/telegrams`

    return axios.put(url, {
        payload: new URLSearchParams(authResponded).toString()
    })
}

export async function getTwitterProfileByWallet(wallet: string) {
    const url = `${ApiUrl}/api/v1/customers/check-verifications/twitters/${wallet}`

    return axios.get(url)
}

export async function loginByEmail(email: string) {
    const url = `${ApiUrl}/api/v1/auth/customers/login-by-email`

    return axios.post(url, {
        email
    })
}

export async function verifyLoginEmail(email: string, otp: string, query: any) {
    const url = `${ApiUrl}/api/v1/auth/customers/login-by-email/verify`

    return axios.post(url, {
        email,
        otp,
        utmSource: query.utm_source,
        utmMedium: query.utm_medium,
        utmCampaign: query.utm_campaign,
        utmId: query.utm_id,
        utmTerm: query.utm_term,
        utmContent: query.utm_content
    })
}

export async function bindWalletAddress(wallet: string, provider: any) {
    const url = `${ApiUrl}/api/v1/auth/customers/bindings/wallet-addresses`

    let payload = {
        walletAddress: wallet,
        deadline: new Date().getTime() + 3600 * 1000,
        signature: ""
    }

    const params = `address: ${payload.walletAddress.toLowerCase()}\ndeadline: ${payload.deadline}`
    payload.signature = await signData(params, wallet, provider)

    return axios.put(url, payload)
}
