import React, { useEffect, useState } from "react"
import { RecentSearch } from "../header/RecentSearch"
import useOutsideClick from "../../hooks/useOutsideClick"
import { useTranslation } from "next-i18next"
import { twMerge } from "tailwind-merge"
import clsx from "clsx"
import useGlobalStore from "../../hooks/useGlobalStore"

interface SearchBoxProps {
    router: any
    className?: string
    classNameInput?: string
    classNameIcon?: string
    isShowRecentSearch?: boolean
}

export const SearchBox = ({ router, className, classNameInput, classNameIcon, isShowRecentSearch }: SearchBoxProps) => {
    const { isLoggedIn } = useGlobalStore()

    const { locale, query } = router
    const { t: tCommon } = useTranslation("common")

    const { s: searchString } = query

    const [keyword, setKeyword] = useState("")
    const handleChange = (event) => {
        setKeyword(event.target.value)
    }

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch()
        }
    }

    const onSearch = () => {
        router.push(
            {
                pathname: "/search",
                query: { ...query, s: keyword || "" }
            },
            undefined,
            { locale: locale }
        )

        setIsOpenRecentSearch(false)
    }

    const handleClickOutside = () => {
        setIsOpenRecentSearch(false)
    }

    const searchRef = useOutsideClick(handleClickOutside)

    const [isOpenRecentSearch, setIsOpenRecentSearch] = useState(false)
    const onSelectRecentSearch = (item) => {
        setKeyword(item.keyword)
        setIsOpenRecentSearch(false)

        router.push(
            {
                pathname: "/search",
                query: { ...query, s: item.keyword }
            },
            undefined,
            { locale: locale }
        )
    }

    useEffect(() => {
        setKeyword(searchString ? searchString.toString() : "")
    }, [query])

    return (
        <div className={twMerge("relative mr-1 ml-[60px] hidden w-[500px] lg:block", className)} ref={searchRef}>
            <input
                type="text"
                value={keyword}
                onFocus={() => setIsOpenRecentSearch(true)}
                onChange={handleChange}
                onKeyPress={onKeyPress}
                placeholder={tCommon("menu.searchPlaceholder")}
                className={twMerge(
                    clsx(
                        "search-border h-9 w-full text-14 text-black",
                        isLoggedIn && isOpenRecentSearch && isShowRecentSearch && "recently"
                    ),
                    classNameInput
                )}
            />
            <button
                className={twMerge(
                    "bg-[length:19px_20px absolute right-0 top-0 h-9 w-14 bg-[url(../assets/svg/icons/search_top.svg)] bg-center bg-no-repeat",
                    classNameIcon
                )}
                onClick={onSearch}
            ></button>
            {isLoggedIn && isOpenRecentSearch && isShowRecentSearch && <RecentSearch onClick={onSelectRecentSearch} />}
        </div>
    )
}
