import React from "react"
import { DiscordSvg, LogoSvg, QuoraSvg, TelegramSvg, TwitterSvg } from "../../assets/svg"
import { useTranslation } from "next-i18next"
import RetainQueryLink from "./RetainQueryLink"

export const Footer = () => {
    const { t: tCommon } = useTranslation("common")

    return (
        <footer className="relative overflow-hidden bg-[#7A1586] pt-[34px] font-primary">
            <div className="mx-auto flex max-w-[1140px] flex-wrap justify-between px-[2vw] xl:px-0">
                <div className="mb-10 flex w-full flex-col items-center lg:mb-0 lg:w-[50%] lg:items-start">
                    <div className="text-3xl font-bold text-neutral-1-100">
                        <LogoSvg width={68} height={34} />
                    </div>
                    <div className={"mt-4 w-[140px]"}>
                        <video playsInline autoPlay muted loop className="h-full w-full object-cover">
                            <source src="/assets/videos/footer.webm" type="video/webm" />
                        </video>
                    </div>
                </div>
                <div className="relative z-10 flex w-full flex-wrap justify-between px-[30px] lg:w-[40%] lg:px-0">
                    <div>
                        <p className="mb-2 font-secondary text-base font-bold leading-5 text-neutral-1-100 lg:text-2xl lg:leading-9">
                            {tCommon("footer.about")}
                        </p>
                        <ul className={"text-neutral-1-100 child-hover:text-accent-1"}>
                            <li>
                                <RetainQueryLink href={"/contact"}>
                                    <a className="text-[12px] leading-[14px] lg:text-xs lg:leading-4">
                                        {tCommon("footer.contact")}
                                    </a>
                                </RetainQueryLink>
                            </li>
                            <li>
                                <a
                                    className="text-[12px] leading-[14px] lg:text-xs lg:leading-4"
                                    href={"https://bbb-9.gitbook.io/bbb-wtf/how-bbb-works/general-mechanics"}
                                    target={"_blank"}
                                    rel={"noreferrer"}
                                >
                                    {tCommon("footer.howItWorks")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-[12px] leading-[14px] lg:text-xs lg:leading-4"
                                    href={"https://docs.bbb.wtf/how-bbb-works/general-mechanics"}
                                    target={"_blank"}
                                    rel={"noreferrer"}
                                >
                                    {tCommon("footer.faq")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-[12px] leading-[14px] lg:text-xs lg:leading-4"
                                    href={"https://medium.com/@BBB.wtf"}
                                    target={"_blank"}
                                    rel={"noreferrer"}
                                >
                                    {tCommon("footer.blog")}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="relative z-10 ml-0 flex flex-col items-end lg:items-start">
                        <p className="text-right font-secondary text-base font-bold leading-5 text-neutral-1-100 lg:text-left lg:text-2xl lg:leading-9">
                            {tCommon("footer.joinTheCommunity")}
                        </p>
                        <div className="mt-5 flex">
                            <figure className="relative mr-0 ml-[26px] w-[22px] lg:mr-[26px] lg:ml-0">
                                <a href={"https://twitter.com/BBB_wtfpusu"} target={"_blank"} rel={"noreferrer"}>
                                    <TwitterSvg className="h-full w-full fill-white hover:fill-accent-1" />
                                </a>
                            </figure>
                            <figure className="relative mr-0 ml-[26px] w-[22px] lg:mr-[26px] lg:ml-0">
                                <a href={"https://t.me/BBB_globalchat"} target={"_blank"} rel={"noreferrer"}>
                                    <TelegramSvg className="h-full w-full fill-white hover:fill-accent-1" />
                                </a>
                            </figure>
                            <figure className="relative mr-0 ml-[26px] w-[22px] lg:mr-[26px] lg:ml-0">
                                <a href={"https://discord.com/invite/wmPK33xWu5"} target={"_blank"} rel={"noreferrer"}>
                                    <DiscordSvg className="h-full w-full fill-white hover:fill-accent-1" />
                                </a>
                            </figure>
                            <figure className="relative mr-0 ml-[26px] w-[22px] lg:mr-[26px] lg:ml-0">
                                <a href={"https://putuporshutupbybbb.quora.com/"} target={"_blank"} rel={"noreferrer"}>
                                    <QuoraSvg className="h-full w-full fill-white hover:fill-accent-1" />
                                </a>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative z-10 mt-10 bg-primary py-5 text-[#F7DFFA] lg:mt-[60px]">
                <div className="mx-auto flex max-w-[1140px] justify-center font-secondary text-14 lg:justify-between">
                    <p className="">{tCommon("footer.copyright")}</p>
                    <div className="flex hidden items-center lg:flex">
                        <a href="" className="text ml-5 block ">
                            {tCommon("footer.termsOfService")}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
