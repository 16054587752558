import React, { useEffect, useRef, useState } from "react"
import {
    BSCSvg,
    DisconnectSvg,
    GameSvg,
    LogoSvg,
    NarrowDownSvg,
    NotificationSvg,
    Person1Svg,
    ProfileSvg,
    SettingSvg,
    WalletSvg
} from "../../assets/svg"
import Account from "../Account"
import useGlobalStore from "../../hooks/useGlobalStore"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import useBBBNetwork from "../../hooks/useBBBNetwork"
import { setupNetwork } from "../../utils/wallet"
import { SUPPORTED_NETWORK } from "../../utils/network"
import SVG from "react-inlinesvg"
import { ArbitrumLogo } from "../../assets/svg/string"
import { Dropdown } from "../forms/dropdown"
import { SearchBox } from "./SearchBox"
import RetainQueryLink from "./RetainQueryLink"
import clsx from "clsx"
import close from "../../assets/imgs/icon/close.png"
import { useDisconnect } from "wagmi"

export const Header = () => {
    const router = useRouter()
    const { query, pathname, asPath } = router

    const isHome = pathname === "/"
    const isMarkets = pathname.indexOf("search") >= 0
    const isClaim = pathname.indexOf("claim") >= 0

    const { isTestnet, currentChainId } = useBBBNetwork()

    const { t: tCommon } = useTranslation("common")

    const { user, setUser, isLoggedIn } = useGlobalStore()

    const { disconnect } = useDisconnect()

    const triedToEagerConnect = true

    const [isShowMenu, setIsShowMenu] = useState(false)

    const onDisconnect = () => {
        disconnect()
        setUser({})
    }

    const [currentLanguage, setCurrentLanguage] = useState(
        router.locale === "en"
            ? { id: "en", label: tCommon("setting.english") }
            : { id: "zh", label: tCommon("setting.chinese") }
    )

    const languageRef = useRef(null)

    const onSelectLanguage = (language) => {
        router.push({ pathname, query }, asPath, { locale: language.id })
    }

    useEffect(() => {
        const language =
            router.locale === "en"
                ? { id: "en", label: tCommon("setting.english") }
                : { id: "zh", label: tCommon("setting.chinese") }

        if (languageRef && languageRef.current) {
            languageRef.current.setSelected(language)

            setCurrentLanguage(language)
        }
    }, [router, tCommon])

    const [isShowChain, setIsShowChain] = useState(false)

    const supportedChains = [
        {
            name: "BNB Chain",
            icon: <BSCSvg width={20} height={20} />,
            largeIcon: <BSCSvg width={23} height={23} />,
            mainnet: SUPPORTED_NETWORK.BSC,
            testnet: SUPPORTED_NETWORK.BSC_TEST
        } /*,
        {
            name: "ThunderCore",
            icon: <ThunderCoreSvg width={20} height={20} />,
            largeIcon: <ThunderCoreSvg width={23} height={23} />,
            mainnet: SUPPORTED_NETWORK.THUNDERCORE,
            testnet: SUPPORTED_NETWORK.THUNDERCORE_TESTNET
        },
        {
            name: "Polygon",
            icon: <PolygonSvg width={20} height={20} />,
            largeIcon: <PolygonSvg width={23} height={23} />,
            mainnet: SUPPORTED_NETWORK.POLYGON,
            testnet: SUPPORTED_NETWORK.POLYGON_TESTNET
        },*/,
        {
            name: "Arbitrum",
            icon: <SVG src={ArbitrumLogo} width={20} height={20} />,
            largeIcon: <SVG src={ArbitrumLogo} width={23} height={23} />,
            mainnet: SUPPORTED_NETWORK.ARBITRUM,
            testnet: SUPPORTED_NETWORK.ARBITRUM_TESTNET
        }
    ]

    const lookupChain = supportedChains.find(
        (x) => parseInt(x.mainnet.chainId) === currentChainId || parseInt(x.testnet.chainId) === currentChainId
    )

    const [selectedChain, setSelectedChain] = useState(lookupChain ?? supportedChains[0])

    const onSwitchChain = async (name) => {
        const supportChain = supportedChains.find((x) => x.name === name)
        if (supportChain) {
            await setupNetwork(isTestnet ? supportChain.testnet : supportChain.mainnet, null, function () {
                setSelectedChain(supportChain)
            })
        }
    }

    useEffect(() => {
        if (currentChainId > 0) {
            const lookupChain = supportedChains.find(
                (x) => parseInt(x.mainnet.chainId) === currentChainId || parseInt(x.testnet.chainId) === currentChainId
            )

            setSelectedChain(lookupChain ?? supportedChains[0])
        }
    }, [currentChainId])

    /*const { numOfNotifications } = useNotificationCount()
    const [isShowNotifications, setIsShowNotifications] = useState(false)

    const handleClickOutsideNotification = () => {
        setIsShowNotifications(false)
    }

    const notificationRef = useOutsideClick(handleClickOutsideNotification)*/

    const [isShowBindRemind, setIsShowBindRemind] = useState(false)

    return (
        <>
            <header className="sticky top-0 z-40 border border-b-[#DFDDDE] bg-white py-3 px-[18px] font-primary lg:sticky lg:top-0 lg:h-[75px] lg:px-[40px]">
                <div className="mx-auto flex items-center justify-between">
                    <div className="flex items-center">
                        <RetainQueryLink href="/">
                            <a className={"b-flex-center flex-col lg:flex"}>
                                <LogoSvg width={68} height={34} className={"hover:opacity-60"} />
                            </a>
                        </RetainQueryLink>
                        <SearchBox router={router} isShowRecentSearch={true} className={"w-[352px]"} />
                        <RetainQueryLink href="/">
                            <a
                                className={clsx(
                                    "ml-[35px] hidden text-14 hover:text-accent-1 lg:inline-block",
                                    isHome && "font-bold"
                                )}
                            >
                                {tCommon("menu.home")}
                            </a>
                        </RetainQueryLink>
                        <RetainQueryLink href="/search">
                            <a
                                className={clsx(
                                    "ml-[35px] hidden text-14 hover:text-accent-1 lg:inline-block",
                                    isMarkets && "font-bold"
                                )}
                            >
                                {tCommon("menu.market")}
                            </a>
                        </RetainQueryLink>
                        <RetainQueryLink href="/claim">
                            <a
                                className={clsx(
                                    "ml-[35px] hidden text-14 hover:text-accent-1 lg:inline-block",
                                    isClaim && "font-bold"
                                )}
                            >
                                {tCommon("button.claim")}
                            </a>
                        </RetainQueryLink>
                        <a
                            href={"https://docs.bbb.wtf"}
                            target={"_blank"}
                            rel={"noreferrer"}
                            className={"ml-[35px] hidden text-14 hover:text-accent-1 lg:inline-block"}
                        >
                            {tCommon("menu.getStarted")}
                        </a>
                        <div className={"ml-[35px] hidden lg:block"}>
                            <RetainQueryLink href={"/create-game"}>
                                <a
                                    className={
                                        "b-flex-center mt-0 h-[36px] w-[82px] rounded-[41px] bg-accent-1 text-14 hover:text-white lg:flex"
                                    }
                                >
                                    {tCommon("button.create")}
                                </a>
                            </RetainQueryLink>
                        </div>
                    </div>

                    <div className="b-flex-center">
                        <div className="b-flex-center ml-1 lg:ml-[20px]">
                            {currentChainId > 0 && (
                                <div
                                    className={
                                        "relative flex h-[40px] items-center justify-center rounded-lg  px-[10px]"
                                    }
                                    onMouseOver={() => setIsShowChain(true)}
                                    onMouseLeave={() => setIsShowChain(false)}
                                >
                                    {selectedChain.largeIcon}
                                    <span className="mx-[6px] text-12 font-semibold text-black lg:text-base">
                                        {selectedChain.name}
                                    </span>
                                    <NarrowDownSvg width={8} height={10} className={"fill-black"} />
                                    {isShowChain && (
                                        <div className="absolute right-0 top-0 z-10">
                                            <ul className="b-flex-center mt-[44px] min-w-[150px] flex-col rounded-[5px] border border-[#DFDDDE] bg-white p-[2px] text-12 font-normal child:relative child:flex child:w-full child:items-center child:justify-start child-hover:rounded-[5px] child-hover:bg-[#F3F3F3] child-hover:text-accent-1 lg:text-14">
                                                {supportedChains.map((chain) => {
                                                    return (
                                                        <li
                                                            className="group h-[35px] cursor-pointer px-4"
                                                            key={chain.name}
                                                            onClick={async () => await onSwitchChain(chain.name)}
                                                        >
                                                            <div className={"mr-2"}>{chain.icon}</div>
                                                            <div>{chain.name}</div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {/*<div className="relative ml-3 mr-2 mt-[10px] lg:mr-5" ref={notificationRef}>
                        <BellSvg
                            onClick={() => setIsShowNotifications((prev) => !prev)}
                            width={25}
                            height={29}
                            className={twMerge(clsx("text-neutral-1-300", numOfNotifications > 0 && "text-accent-1"))}
                        />
                        {numOfNotifications > 0 && (
                            <span
                                className={
                                    "b-flex-center absolute top-[-8px] right-[-8px] h-[19px] w-[19px] rounded-full border border-neutral-1-500 bg-accent-3 font-secondary text-ssm text-white"
                                }
                            >
                                {numOfNotifications}
                            </span>
                        )}
                        {isShowNotifications && <Notifications onClick={() => setIsShowNotifications(false)} />}
                    </div>*/}
                        <div className="b-flex-center group relative ml-4 h-[36px] w-[128px] items-center justify-center rounded-[41px] border-[0.5px] border-[rgba(0,0,0,0.5)] lg:w-[130px]">
                            <WalletSvg width={18} height={13} />
                            <div className="ml-2 lg:ml-[13px]">
                                <Account triedToEagerConnect={triedToEagerConnect} />
                            </div>
                            {isLoggedIn && (
                                <div className="absolute -left-6 z-10 hidden group-hover:block lg:left-auto">
                                    <button
                                        className="b-flex-center mt-[100px] h-[41px] justify-between rounded-[5px] border border-[#DFDDDE] bg-white p-[2px] px-[15px] text-14 font-normal hover:text-accent-1 lg:w-[171px]"
                                        onClick={onDisconnect}
                                    >
                                        {tCommon("menu.logout")}{" "}
                                        <DisconnectSvg width={20} height={17} className={"fill-black"} />
                                    </button>
                                </div>
                            )}
                        </div>
                        <div
                            className="relative ml-[17px] hidden lg:block"
                            onMouseOver={() => setIsShowMenu(true)}
                            onMouseLeave={() => setIsShowMenu(false)}
                        >
                            <div
                                className={
                                    "flex h-[36px] w-[75px] items-center rounded-[41px] border-[0.5px] border-[rgba(0,0,0,0.5)] px-[5px]"
                                }
                            >
                                {user && user.profileImage ? (
                                    <div className={"search-border b-flex-center h-[29px] w-[29px]"}>
                                        <div className={"h-[26px] w-[26px]"}>
                                            <img
                                                src={user.profileImage}
                                                className={"h-full w-full rounded-full object-cover"}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <ProfileSvg width={29} height={29} />
                                )}
                                <div className={"ml-2"}>
                                    <div className="h-[2px] w-[22px] bg-black"></div>
                                    <div className="my-[7px] h-[2px] w-[21px] bg-black"></div>
                                    <div className="h-[2px] w-[22px] bg-black"></div>
                                </div>
                            </div>
                            {isShowMenu && (
                                <div className="absolute right-0 z-10">
                                    <ul className="b-flex-center mt-[6px] w-[162px] flex-col rounded-[5px] border border-[#DFDDDE] bg-white p-[2px] text-14 font-normal child:relative child:flex child:w-full child:items-center child:justify-start child-hover:rounded-[5px] child-hover:bg-[#F3F3F3] child-hover:text-accent-1">
                                        <li className="group h-[35px] cursor-pointer">
                                            <RetainQueryLink href={"/my-games"}>
                                                <a className={"flex items-center"}>
                                                    <div
                                                        className={
                                                            "b-flex-center mr-[14px] ml-[18px] h-[20px] w-[20px]"
                                                        }
                                                    >
                                                        <GameSvg
                                                            width={19}
                                                            height={13}
                                                            className="fill-[#546F7A] group-hover:fill-accent-1 group-hover:text-accent-1"
                                                        />
                                                    </div>
                                                    {tCommon("menu.myGames")}
                                                </a>
                                            </RetainQueryLink>
                                        </li>

                                        <li className="group h-[35px] cursor-pointer">
                                            <RetainQueryLink href={"/my-account"}>
                                                <a className={"flex items-center"}>
                                                    <div className={"mr-[14px] ml-[18px]"}>
                                                        <Person1Svg
                                                            width={17}
                                                            height={17}
                                                            className="fill-[#546F7A] group-hover:fill-accent-1 group-hover:text-accent-1"
                                                        />
                                                    </div>
                                                    {tCommon("menu.personalInfo")}
                                                </a>
                                            </RetainQueryLink>
                                        </li>
                                        <li className="group h-[35px] cursor-pointer">
                                            <RetainQueryLink href={"/notifications"}>
                                                <a className={"flex items-center"}>
                                                    <div
                                                        className={
                                                            "b-flex-center mr-[14px] ml-[18px] h-[20px] w-[20px]"
                                                        }
                                                    >
                                                        <NotificationSvg
                                                            width={16}
                                                            height={18}
                                                            className="fill-[#546F7A] group-hover:fill-accent-1 group-hover:text-accent-1"
                                                        />
                                                    </div>
                                                    {tCommon("menu.notifications")}
                                                </a>
                                            </RetainQueryLink>
                                        </li>
                                        <li className="group h-[35px] cursor-pointer">
                                            <RetainQueryLink href={"/preferences"}>
                                                <a className={"flex items-center"}>
                                                    <div
                                                        className={
                                                            "b-flex-center mr-[14px] ml-[18px] h-[20px] w-[20px]"
                                                        }
                                                    >
                                                        <SettingSvg
                                                            width={18}
                                                            height={18}
                                                            className="fill-[#546F7A] group-hover:fill-accent-1 group-hover:text-accent-1"
                                                        />
                                                    </div>
                                                    {tCommon("menu.preferences")}
                                                </a>
                                            </RetainQueryLink>
                                        </li>
                                        <li className="group h-[35px] cursor-pointer hover:bg-transparent">
                                            <div
                                                className={
                                                    "ml-[18px] mr-[18px] flex w-full items-center justify-between"
                                                }
                                            >
                                                <div>{tCommon("setting.language")}</div>
                                                <div className={"w-[60px]"}>
                                                    {currentLanguage && (
                                                        <Dropdown
                                                            ref={languageRef}
                                                            items={[
                                                                {
                                                                    id: "en",
                                                                    label: tCommon("setting.english")
                                                                },
                                                                {
                                                                    id: "zh",
                                                                    label: tCommon("setting.chinese")
                                                                }
                                                            ]}
                                                            defaultSelected={currentLanguage}
                                                            buttonClassNames="h-[36px] ml-4 bg-transparent hover:bg-transparent text-xs hover:text-black"
                                                            listClassNames={"text-xs"}
                                                            itemClassNames={"text-xs"}
                                                            narrowClassNames={
                                                                "w-[10px] group-hover:fill-black h-[10px] b-insert-center"
                                                            }
                                                            afterSelected={onSelectLanguage}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </header>
            {isLoggedIn && !user.walletAddress && !isShowBindRemind && (
                <div className={"b-flex-center h-[45px] w-full bg-[#FFC90A] text-center text-11 lg:text-base"}>
                    <div className={"max-w-[267px] lg:max-w-none"}>
                        <RetainQueryLink href={"/my-account"}>
                            <a className={"font-bold text-[#0085FF] underline hover:text-primary"}>
                                {tCommon("button.bind")}
                            </a>
                        </RetainQueryLink>
                        <span>&nbsp;{tCommon("remindBind")}</span>
                        <span
                            className={"ml-2 rounded-[5px] border-[2px] border-black px-2 font-bold lg:border lg:px-4"}
                        >
                            {tCommon("goo")}
                        </span>
                    </div>
                    <img
                        src={close.src}
                        onClick={() => setIsShowBindRemind(true)}
                        className="absolute right-[10px] w-[8px] hover:opacity-50 lg:right-[20px]"
                    />
                </div>
            )}
        </>
    )
}
