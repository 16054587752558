import React from "react"
import { RecentlySvg } from "../../assets/svg"
import useGlobalStore from "../../hooks/useGlobalStore"
import useSWR, { useSWRConfig } from "swr"
import { deleteRecentSearch, getRecentSearch } from "../../services/search"
import { useTranslation } from "next-i18next"

interface RecentlySearchProps {
    onClick?: (item) => void
}

export const RecentSearch = ({ onClick }: RecentlySearchProps) => {
    const { t: tCommon } = useTranslation("common")

    const { isLoggedIn } = useGlobalStore()

    const fetcher = async () => {
        const response = await getRecentSearch()

        return response.data.data.list
    }
    const { mutate } = useSWRConfig()
    const { data: recentSearches } = useSWR(isLoggedIn ? "RECENT_SEARCH" : null, fetcher)

    const onDelete = async (id: string) => {
        await deleteRecentSearch(id)

        await mutate("RECENT_SEARCH")
    }

    return (
        <div className={"absolute w-full"}>
            {recentSearches && recentSearches.length > 0 && (
                <div className={"search-border rounded-[5px] bg-white text-14 recently-results"}>
                    {recentSearches.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className={
                                    "group flex h-[35px] items-center justify-between hover:rounded-[3px] hover:bg-[#F3F3F3]"
                                }
                            >
                                <div className={"flex flex-1 items-center"} onClick={() => onClick(item)}>
                                    <RecentlySvg width={13} height={13} className={"mx-4"} />
                                    <span className={"break-all indent-0"}>{item.keyword}</span>
                                </div>
                                <div className={"mr-4 flex"}>
                                    <button
                                        onClick={() => onDelete(item.id)}
                                        className={"text-ssm1 font-normal text-[#1796BE] group-hover:underline"}
                                    >
                                        {tCommon("button.remove")}
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
