import React from "react"
import close from "../../assets/imgs/home/bg_x.png"
import Account from "../Account"
import StyledModal, { ModalProps } from "../StyledModal"
import { useTranslation } from "next-i18next"

export const LoginModal = ({ isOpen, onRequestClose }: ModalProps) => {
    const { t: tCommon } = useTranslation("common")

    const triedToEagerConnect = false

    return (
        <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
            <div className="relative mx-auto h-[230px] w-[90%] rounded-[11px] border border-[#808080] bg-[#F8F8F8] text-center lg:w-[432px]">
                <div className="absolute right-[13px] top-[13px] text-right">
                    <img src={close.src} onClick={onRequestClose} className="w-[15px] hover:opacity-50 lg:w-[20px]" />
                </div>
                <div className="pt-[44px] text-28 font-bold text-neutral-1-500">{tCommon("account.required")}</div>
                <div className="mt-[10px] text-base text-neutral-1-500">{tCommon("account.description")}</div>
                <div>
                    <Account
                        triedToEagerConnect={triedToEagerConnect}
                        background={""}
                        buttonClassNames="b-flex-center h-[34px] w-[153px] rounded-[9px] bg-[#FFC90A] font-secondary text-base font-bold leading-6 hover:text-white mt-6 mx-auto text-black"
                    />
                </div>
            </div>
        </StyledModal>
    )
}
