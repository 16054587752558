import { useContext } from "react"
import { Context } from "../contexts/GlobalContext"
import { PopupMessageProps } from "../contexts/GlobalContext/GlobalContextProvider"

interface GlobalStoreContext {
    isLoggedIn: boolean
    isValidAccount: boolean
    account: string
    user: any
    setUser: (user: any) => void
    isOpenLoginModal: boolean
    setIsOpenLoginModal: (isOpen: boolean) => void
    popupMessage: PopupMessageProps
    setPopupMessage: (item: PopupMessageProps) => void
}

const useGlobalStore = () => {
    const {
        isLoggedIn,
        isValidAccount,
        account,
        user,
        setUser,
        isOpenLoginModal,
        setIsOpenLoginModal,
        popupMessage,
        setPopupMessage
    } = useContext<GlobalStoreContext>(Context)

    return {
        isLoggedIn,
        isValidAccount,
        account,
        user,
        setUser,
        isOpenLoginModal,
        setIsOpenLoginModal,
        popupMessage,
        setPopupMessage
    }
}

export default useGlobalStore
